import '../empresas.css';
import wot20 from '../iconos/wot20.png';
import wot24 from '../iconos/wot24.png';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from '../componentes/Header';

const logout = () => {
    localStorage.removeItem('accessToken');
    window.location.reload();
}
 
const BusquedaItem =  ({ matchs, handleCheckboxChange, contador }) => {
    return (
      <div className="subcontainerMiPerfil">
        <h className='normalMiPerfil'>Resultado {contador}</h>
        <p className='normalMiPerfil'></p>
        <input
        type="checkbox"
        checked={matchs.isSelected}
        onChange={() => handleCheckboxChange(matchs.id, matchs.isSelected)}
        />
        <Link id="detalle" to={{ pathname: `/detalleUsuaria/${matchs.usuaria.id}`}}>Ver Detalle</Link>
      </div>
    );
  }

const Busqueda = () => {
    const jwtToken = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('user'));
    const [matchs, setMatchs] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();
    const [busqueda, setBusqueda] = useState();
    const [selectedResults, setSelectedResults] = useState([]);
    const [selected, setSelected] = useState([]);

    const sendSlackMessage = (message) => {
        const backendUrl = `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/slack/enviar-mensaje-slack`;
        const payload = {
          message: message,
        };
        
        // Incluye el token JWT en el encabezado de la solicitud
        const headers = {
          Authorization: `Bearer ${jwtToken}`,
        };
      
        axios
          .post(backendUrl, payload, { headers })
          .then((response) => {
            console.log('Mensaje enviado a Slack con éxito:', response.data);
          })
          .catch((error) => {
            console.error('Error al enviar el mensaje a Slack desde el frontend:', error.message);
          });
      };
      
    const handleCheckboxChange = (id, isSelected) => {
        const updatedMatchs = matchs.map(match => {
        if (!isSelected) {
            selected.push(match.id);
        } else {
            const index = selected.indexOf(match.id);
            if (index > -1) {
            selected.splice(index, 1);
            }
        }
        if (match.id === id) {
            console.log(!isSelected);
            return { ...match, isSelected: !isSelected };
        }
        return match;
        });
        setMatchs(updatedMatchs);
        console.log(updatedMatchs);
    };

     const handleDesbloquearClick = () => {
        const selected = matchs.filter(match => match.isSelected);
        setSelectedResults(selected);
        console.log(selected);
        console.log(selectedResults);
        alert("Estimado cliente, hemos recibido tu solicitud para hacer match para tu proceso de búsqueda. Validaremos los datos de las candidatas y te enviamos la información a la brevedad.");
        const message = selectedResults;
        sendSlackMessage(message);
  };

    useEffect(() => {
        if (!jwtToken) {
          navigate('/login');
        } 
      }, [jwtToken, navigate]);

    useEffect(() => {
        console.log(id)
        axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/obtenerids/matchs/${id}`, {
            headers:{
                authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            const data = response.data;
            console.log('Match', data)
            setMatchs(data)
        })
        .catch(error => {
            console.log(error);
        })
    }, []);

    useEffect(() =>{
        console.log(id)
        axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/busqueda/${id}`,{
            headers:{
                authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            const data = response.data;
            console.log("busqueda",data)
            setBusqueda(data)
        })
        .catch(error => {
            console.log(error);
        })
    },[]);
    if (!busqueda) return 'Cargando...'; 

    return (
    <div className="WT1">
        <Header />  

        <div className='containerMiPerfil2 containerReversed2'>
         <p className='boldMiPerfil'> Resultados Búsqueda</p>
         <p className='normalMiPerfil'> Acá se puede ver un resumen de los mejores 20 resultados encontrados.</p>
         {matchs.map((matchs, index) => <BusquedaItem key={index} matchs={matchs} handleCheckboxChange={handleCheckboxChange} contador={index + 1}/>)}
        
        </div>
        <div className='containerMiPerfil1 containerReversed1'>
            <p className='boldMiPerfil'>Desbloquear Resultados</p>
            <p className='normalMiPerfil'> Se contactará a Catalina Braun encargada para desbloquear los resultados.</p>
            {selected.length !== 0 ? (
                <a id="busqueda" onClick={handleDesbloquearClick}>Desbloquear y Enviar</a>
            ) : null }

        </div>

        <div className='rectanguloEmpresas'>
            <p className='boldMiPerfil'> Nombre Búsqueda: {busqueda.nombre}</p>
            <p className='normalMiPerfil'>Cargo: {busqueda.cargo.cargo}</p>
            <p className='normalMiPerfil'>Profesiones: {busqueda.profesiones[0].nombre}</p>
            <p className='normalMiPerfil'>Jornada: {busqueda.jornada.tipoJornada} </p>
            <p className='normalMiPerfil'>Modalidad: {busqueda.modalidad.tipoModalidad}</p>
            {/* <p className='normalMiPerfil'>Años de Experiencia: {busqueda.anios.rango_experiencia_desde}-{busqueda.anios.rango_experiencia_hasta}</p> */}
            {busqueda.areas.length !== 0 ? (
                <p className='normalMiPerfil'>Areas de Experiencia: {busqueda.areas[0].nombre}</p>
            ) : (
                <p className='normalMiPerfil'>Áreas de Experiencia: No seleccionó áreas de experiencia</p>
            )}       
            <p className='normalMiPerfil'>Industrias de Experiencia: {busqueda.industrias[0].nombre_industria}</p>   
        </div>
        <img src={wot24} alt='circulo' className='wot24Empresas' />
    </div>
    )
}

export default Busqueda